import { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import Layout from "./components/layout/Layout";

class App extends Component {
  render() {
    return (
      <>
        <Layout />
      </>
    );
  }
}

export default App;
