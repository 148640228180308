import { Component } from "react";



class MapAtlungstad extends Component{
    render() {
    return (
        <>
       <iframe title="Google Maps Atlungstad Brenneri" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1801.769361452241!2d11.07763541601674!3d60.75911508218179!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4641e6bb622a9907%3A0x55f63a2d0c962405!2sAtlungstad%20Distillery!5e1!3m2!1sen!2sno!4v1619617505920!5m2!1sen!2sno" width="300" height="200"  allowfullscreen="" loading="lazy"></iframe>
        </>
        
    );
    }
}

export default MapAtlungstad;